import { Heading } from '@loveholidays/design-system';
import React, { Fragment } from 'react';

import { headingStyles } from '../styles';
import { FaqsSectionProps } from '@AuroraTypes';
import { hastToReact } from '@UX/Hast/Hast';
import { Tab } from '@UX/Tabs/Tab';
import { VerticalTabs } from '@UX/Tabs/VerticalTabs';

export const FaqsSection: React.FC<FaqsSectionProps> = ({ faqs, title }) => (
  <Fragment>
    <Heading
      as="h2"
      variant={['large', 'xlarge']}
      sx={headingStyles}
    >
      {title}
    </Heading>
    <VerticalTabs prefix="faq-section">
      {faqs.items.map((faq) => (
        <Tab
          key={faq.question}
          title={faq.question}
          sx={{
            paddingX: ['xs', 0],
          }}
        >
          {hastToReact(faq.answer)}
        </Tab>
      ))}
    </VerticalTabs>
  </Fragment>
);
